import { template as template_1deeb8a5afbe470e9a4e0cd254c131e7 } from "@ember/template-compiler";
const FKLabel = template_1deeb8a5afbe470e9a4e0cd254c131e7(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
